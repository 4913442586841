import React, { useState } from "react";
import "./App.css";

function Square({ value, onClick }) {
  return (
    <button className="square" onClick={onClick}>
      {value}
    </button>
  );
}

function Board(payers) {
  const [squares, setSquares] = useState(Array(9).fill(null));
  const [isXNext, setIsXNext] = useState(true);

  const handleClick = (index) => {
    if (squares[index] || calculateWinner(squares)) return;

    const newSquares = squares.slice();
    if (payers.payers === 1) {
      setSquares(makeMove(newSquares, index, "X"));
      // setTimeout(() => {

      // }, 1000);
      let move = bestMove(newSquares);
      setSquares(makeMove(newSquares, move, "O"));
    } else if (payers.payers === 2) {
      newSquares[index] = isXNext ? "X" : "O";
      setSquares(newSquares);
      setIsXNext(!isXNext);
    }
  };

  const renderSquare = (index) => {
    return <Square value={squares[index]} onClick={() => handleClick(index)} />;
  };

  const winner = calculateWinner(squares);
  if (winner) {
    alert("¡Enhorabuena " + winner + "!");
  } else if (calculateTie(squares)) {
    alert("¡Es un empate!");
  }

  return (
    <div className="board">
      <div className="board-row">
        {renderSquare(0)}
        {renderSquare(1)}
        {renderSquare(2)}
      </div>
      <div className="board-row">
        {renderSquare(3)}
        {renderSquare(4)}
        {renderSquare(5)}
      </div>
      <div className="board-row">
        {renderSquare(6)}
        {renderSquare(7)}
        {renderSquare(8)}
      </div>
    </div>
  );
}

function calculateWinner(squares) {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
}

function calculateTie(squares) {
  for (let i = 0; i < squares.length; i++) {
    if (squares[i] == null) {
      return false;
    }
  }
  return true;
}

function makeMove(squares, position, player) {
  if (squares[position] == null) {
    squares[position] = player;
    return squares;
  }
  //return false;
}

function minimax(squares, depth, isMaximizing) {
  if (calculateWinner(squares) === "O") {
    return 1;
  }

  if (calculateWinner(squares) === "X") {
    return -1;
  }

  if (calculateTie(squares)) {
    return 0;
  }

  if (isMaximizing) {
    let bestScore = -100;
    for (let i = 0; i < squares.length; i++) {
      if (squares[i] == null) {
        squares[i] = "O";
        let score = minimax(squares, depth + 1, false);
        squares[i] = null;
        bestScore = Math.max(score, bestScore);
      }
    }
    return bestScore;
  } else {
    let bestScore = 100;
    for (let i = 0; i < squares.length; i++) {
      if (squares[i] == null) {
        squares[i] = "X";
        let score = minimax(squares, depth + 1, true);
        squares[i] = null;
        bestScore = Math.min(score, bestScore);
      }
    }
    return bestScore;
  }
}

function bestMove(squares) {
  let bestScore = -100;
  let move = null;
  for (let i = 0; i < squares.length; i++) {
    if (squares[i] == null) {
      squares[i] = "O";
      let score = minimax(squares, 0, false);
      squares[i] = null;
      if (score > bestScore) {
        bestScore = score;
        move = i;
      }
    }
  }
  return move;
}

function App() {
  const [key, setKey] = useState(0);
  const [players, setPlayers] = useState(0);

  const again = () => {
    setKey((prevKey) => prevKey + 1);
  };

  return (
    <div className="game">
      {players === 0 ? (
        <div className="init">
          <h1>Tic Tac Toe</h1>
          <div className="menu">
            <h2>Opciones de juego</h2>
            <ol>
              <li>
                <button onClick={() => setPlayers(1)}>Un jugador</button>
              </li>
              <li>
                <button onClick={() => setPlayers(2)}>Dos jugadores</button>
              </li>
            </ol>
          </div>
        </div>
      ) : (
        <div className="game-board">
          <div>
            <Board payers={players} key={key} />
          </div>
          <div className="options">
            <button onClick={again} className="again">
              Otra vez
            </button>
            <button onClick={() => setPlayers(0)} className="exit">
              Salir
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
